import { createStorage } from 'react-trick-local-storage';

interface Fields {
  accessToken: string | undefined;
  kitchenUuid: string | undefined;
  newOrderSoundEnabled: boolean | undefined;
}

export const localStorage = createStorage<Fields>({
  key: process.env.REACT_APP_LOCAL_STORAGE_KEY,
});
