import { getMe, getPhoneCountryCodes } from '@shared/modules/api';
import { accessToken } from './configureApis';

export const bootstrap = async () => {
  const [{ runApp }, initialSession, phoneCodes] = await Promise.all([
    import('./runApp'),
    fetchSession().catch(() => undefined),
    getPhoneCountryCodes({ by: 'unscoped' }).catch(() => []),
  ]);

  runApp({ initialSession, phoneCodes });
};

const fetchSession = async () => {
  if (!accessToken) {
    return undefined;
  }

  return getMe();
};
