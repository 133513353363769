import { bootstrap } from 'src/bootstrap';
import { renderErrorScreen } from '@shared/helpers/renderErrorScreen';

bootstrap()
  .catch(() => {
    // FIXME report error to rollbar
    const container = document.createElement('div');
    document.body.appendChild(container);
    renderErrorScreen({
      container,
      code: 500,
      message: 'Что-то пошло не так',
      action: {
        label: 'Обновить страницу',
        onClick: () => window.location.reload(),
      },
    });
  })
  .finally(() => {
    const loader = document.getElementById('loader');
    if (loader) document.body.removeChild(loader);
    // loader?.classList.add('completed');
  });
