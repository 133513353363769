import { core, dadata } from '@shared/modules/api';
import { localStorage } from 'src/localStorage';

export const accessToken = localStorage.get('accessToken');

core.setVariable('baseUrl', process.env.REACT_APP_API_URL);
if (accessToken) core.setVariable('accessToken', accessToken);

// core.addResponseInterceptor(({ response, options }) => {
//   if (response.error?.kind === 'response' && response.status !== 422) {
//     const error = response.error;
//     import('src/helpers/rollbar').then((m) => {
//       m.reportError(error, {
//         response,
//         options: {
//           ...options,
//           params: {
//             ...options.params,
//             headers: {
//               ...options.params.headers,
//               'X-Access-Token': '******',
//             },
//           },
//         },
//       });
//     });
//   }

//   return response;
// });

dadata.setVariable('apiKey', process.env.REACT_APP_DADATA_API_KEY);
